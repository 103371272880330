import React, { Fragment, useEffect, useState, useMemo } from "react";
import { TableHeader, Pagination, Search } from "../../components/DataTable/DataTable.js";
//import useFullPageLoader from "../../hooks/useFullPageLoader";
import "./ListadoInvitados.css"
const ListadoInvitados = () => {
    const [comments, setComments] = useState([]);
    //const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    // Para el download 
    const [cabecera, setCabecera] = useState("");

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "No#", field: "idRsvp", sortable: true },
        { name: "Invitador", field: "campo1", sortable: true },
        { name: "Cantidad adultos", field: "campo2", sortable: true },
        { name: "Cantidad niños",     field: "campo3", sortable: true },
        { name: "Alergias etc.",   field: "campo4", sortable: true },
        { name: "Requiere Bus",   field: "campo5", sortable: true },
        { name: "Requiere Alojamiento",  field: "campo6", sortable: true },
        { name: "Habitaciones", field: "campo7", sortable: true },
        { name: "", field: "campo8", sortable: true },
        { name: "",   field: "campo9", sortable: true },
        { name: "",  field: "campo10", sortable: true },        
    ];

    useEffect(() => {
        
        const getData = () => {
            //showLoader();

            fetch("https://acolores.es/ecard6/td271/backend/rsvp.php?all")
                .then(response => response.json())
                .then(json => {
                    //hideLoader();
                    setComments(json);
                    console.log(json);
                }).catch( error => {
                    console.log(error)
                });
        };

        getData();
    }, []);

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToCsv = e => {
        e.preventDefault()

        // Headers for each column
        let cabeceraCSV = ['Id;Adultos;Cantidad adultos;Canticad chicos;Alergias etc.;Bus;Alojamiento;Habitaciones;N/A;N/A;N/A']
        // let cabeceraCSV = JSON.parse(headers, (key, value) => {
        //     if (typeof value === 'string') {
        //       return value.toUpperCase();
        //     }
        //     return value;
        //   });
        // examplecampo4 === "0" ? "YES" : "NO"
        // Convert users data to a csv
        let usersCsv = comments.reduce((acc, user) => {
            const { idRsvp, campo1, campo2, campo3, campo4, campo5, campo6, campo7, campo8, campo9, campo10,} = user
            acc.push([idRsvp, campo1, campo2, campo3, campo4 , campo5 , campo6 , campo7 , campo8 , campo9, campo10 ,].join(';'))
            return acc
        }, [])

        console.log(usersCsv);

        downloadFile({
            data: [...cabeceraCSV, ...usersCsv].join('\n'),
            fileName: 'ListaInvitados.csv',
            fileType: 'text/csv',
        })
    }

    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
                    comment.mail.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting]);

    const styleBoton = {
        marginTop: '0px',        
      };

    return (
        <Fragment>
            <br />        
            <div id="contennedorUno" className="row w-100">
                <div id="contennedorDos" className="col mb-3 col-12 text-center">
                    <div className="row">
                        <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-4">
                        <button className="btn btn-secondary btn-lg" onClick={exportToCsv} style={styleBoton}><span> - Descargar Lista - </span>  </button>
                        </div>
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>

                    <table className="table table-striped">
                        <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                        <tbody>
                            {commentsData.map(comment => (
                                <tr key={comment.idRsvp}>
                                         <th scope="row" >
                                        {comment.idRsvp}
                                    </th>
                                    <td>{comment.campo1}</td>
                                    <td>{comment.campo2}</td>
                                    

                                    <td>{comment.campo3}</td>
                                    <td>{comment.campo4}</td>

                                    <td>{comment.campo5}</td>

                                    {/* <td>{comment.comeCerdo === "0" ? "NO" : "SI"}</td> */}

                                    <td>{comment.campo6}</td>
                                    <td>{comment.campo7}</td>
                                    <td>{comment.campo8}</td>
                                    <td>{comment.campo9}</td>
                                    <td>{comment.campo10}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </Fragment>
    );
};
 
export default ListadoInvitados;

