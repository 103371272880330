import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_5/data.json';
import ConfirmarAsistenciaBoton from '../../components/ConfirmarAsistenciaBoton/ConfirmarAsistenciaBoton';
import "./Seccion_5.css"

const Seccion_5 = (props) => {

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            <img id="fonddoSeccion5" src="./imgFondos/5.jpg"></img>

            <div id="textoAsistencia">
                <img src="./img/Asistencia.svg" alt=""/>
                <div id="ConfirmarAsistenciaBoton" >
                    <ConfirmarAsistenciaBoton/>
                </div>
            </div> 

            <object id="textodress" type="image/svg+xml" data="./img/dress.svg" ></object>

        </Fragment>

    );
}
 
export default Seccion_5