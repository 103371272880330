import React, {useRef, useEffect, useState, Fragment} from 'react'
import { useLocation } from "react-router-dom";

import "./HorizontalScrollEcard.css"
import MensajeContextoProvider from "./Mensaje/MensajeContextoProvider";

// Json Lotties
import Seccion_1 from './Seccion_1/Seccion_1'
import Seccion_2 from './Seccion_2/Seccion_2'
import Seccion_3 from './Seccion_3/Seccion_3'
import Seccion_4 from './Seccion_4/Seccion_4'
import Seccion_5 from './Seccion_5/Seccion_5'
import Seccion_6 from './Seccion_6/Seccion_6'
import Seccion_7 from './Seccion_7/Seccion_7'
import Seccion_8 from './Seccion_8/Seccion_8'
import Funnel from '../funnel/Funnel';

const HorizontalScrollEcard = (props) => {

    let {search} = useLocation();
    let query = new URLSearchParams(search);

    const [altura, setAltura] = useState(0)
    const [ancho, setAncho]   = useState(0)
  
    useEffect(() => {
        setAltura( window.innerHeight )
        const anchoAux = (window.innerHeight * 57) / 100
        setAncho( anchoAux )
        console.log( "altura: " + altura + "ancho: " + ancho  )
    }, [altura, ancho])

    const styleImage = {
        top: "40%",
        padding: 0,
        border: 0,
        height: "20vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 0%)",
        zIndex: "100"
      };
      
    return ( 
        <Fragment>
            <MensajeContextoProvider>
                <div>
                    <div id="uno" className="tarjeta" >
                        <Seccion_1 altura={altura} ancho={ancho}/>
                    </div>
                     <div id="dos" className="tarjeta">
                        <Seccion_2 altura={altura} ancho={ancho}/>
                    </div>
                    <div id="tres" className="tarjeta">
                        <Seccion_3 altura={altura} ancho={ancho}/>
                    </div>
                    <div id="tres" className="tarjeta">
                        <Seccion_4 altura={altura} ancho={ancho}/>
                    </div>
                    <div id="tres" className="tarjeta">
                        <Seccion_5 altura={altura} ancho={ancho}/>
                    </div>
                    <div id="tres" className="tarjeta">
                        <Seccion_6 altura={altura} ancho={ancho}/>
                    </div>
                    <div id="tres" className="tarjeta">
                        <Seccion_7 altura={altura} ancho={ancho}/>
                    </div>  
                    <div id="tres" className="tarjeta">
                        <Seccion_8 altura={altura} ancho={ancho}/>
                    </div>   
                </div>
                {query.get("mkt") === null ? "" :  <Funnel/> }     
            </MensajeContextoProvider>
        </Fragment>                  
    );

}
 
export default HorizontalScrollEcard;