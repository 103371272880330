import React, {useContext, Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_1/data.json';

import MensajeContexto from '../Mensaje/MensajeContexto';

import "./Seccion_1.css"

const Seccion_1 = (props) => {

    const { mostrarMensaje, updateMostrarMensaje } = useContext(MensajeContexto);

    const style = {
        top: "0%",
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, -5%)",
      };  

    const mensaje = () => {
        const timeout = setTimeout(() => {
            updateMostrarMensaje(true);
        }, 100)      
    }    

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
                onLoopComplete={mensaje}
            />
            <img id="fonddoSeccion1" src="./imgFondos/1.jpg"></img>

            {mostrarMensaje ? <div>
                                  <div class="scroll-up"></div>
                                  <div class="scroll-up2"></div>
                                  <div class="scroll-up3"></div>
                              </div>
                : "" }    
        </Fragment>
    );
}
 
export default Seccion_1