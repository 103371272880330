import React, {useRef, useEffect, useContext, Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_2/data.json';
import MensajeContexto from '../Mensaje/MensajeContexto';

import Countdown from '../Countdown/Countdown';
import Timer from '../Countdown/Timer';

import "./Seccion_2.css"

const Seccion_2 = (props) => {

    const refSeccion2 = useRef(null);

    const { mostrarMensaje, updateMostrarMensaje } = useContext(MensajeContexto);

    const scrollCallback = (entries) => {
      if (entries[0].isIntersecting) {
          updateMostrarMensaje(false);
      }else{
          
      }
  };

  useEffect(() => {
     
      const { current } = refSeccion2;
      const observer = new IntersectionObserver(scrollCallback, {
          root: null,
          threshold: 1,
      });
      observer.observe(current);
      return () => {
          observer.disconnect(current); 
      }
  }, [refSeccion2.current]); 

    const styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 100%)",
        zIndex: "-100"
      };

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            <img id="fonddoSeccion2" src="./imgFondos/2.jpg"></img>

            <div id="seccion2Box" ref={refSeccion2}></div>
        </Fragment>
    );
}
 
export default Seccion_2