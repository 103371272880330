import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_3/data.json';

import "./Seccion_3.css"

const Seccion_3 = (props) => {

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            <img id="fonddoSeccion3" src="./imgFondos/3.jpg" ></img>

            <object id="textoIglesia" type="image/svg+xml" data="./img/Iglesia.svg"></object>

        </Fragment>
    );
}
 
export default Seccion_3